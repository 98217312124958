const isLeftToRight = (theme) => theme.direction === 'ltr'

const fontFamily = (theme) => {
  if (isLeftToRight(theme)) {
    return ['"Source Sans Pro"', 'Arial', 'sans-serif'].join(', ')
  }

  return ['Tajawal', 'Tahoma', 'sans-serif'].join(', ')
}

const fontWeight = (theme, type = 'regular') => {
  switch (type) {
    case 'regular':
      return 400
    case 'semibold':
      return isLeftToRight(theme) ? 600 : 700
    case 'bold':
      return 700
    default:
  }
}

const letterSpacing = (theme) => (isLeftToRight(theme) ? '-0.1px' : 0)

const d1 = (theme) => ({
  fontSize: '5.25rem',
  lineHeight: '5.75rem',
  fontFamily: fontFamily(theme),
  letterSpacing: isLeftToRight(theme) ? '-0.2px' : 0,

  [theme.breakpoints.down('md')]: {
    fontSize: '4rem',
    lineHeight: '4.5rem',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
})

const d2 = (theme) => ({
  fontSize: '2.75rem',
  lineHeight: '3.2rem',
  fontFamily: fontFamily(theme),
  letterSpacing: letterSpacing(theme),

  [theme.breakpoints.down('md')]: {
    fontSize: '2.25rem',
    lineHeight: '3rem',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
})

const h1 = (theme) => ({
  fontSize: '1.75rem',
  lineHeight: '2.25rem',
  fontFamily: fontFamily(theme),
  letterSpacing: letterSpacing(theme),

  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
})

const h2 = (theme) => ({
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  fontFamily: fontFamily(theme),
  letterSpacing: letterSpacing(theme),
})

const h3 = (theme) => ({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontFamily: fontFamily(theme),
  letterSpacing: letterSpacing(theme),
})

const h4 = (theme) => ({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontFamily: fontFamily(theme),
  letterSpacing: letterSpacing(theme),
})

const body1 = (theme) => ({
  letterSpacing: 0,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme),
})

const body2 = (theme) => ({
  letterSpacing: 0,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontFamily: fontFamily(theme),
  fontWeight: fontWeight(theme),
})

export default (theme) => ({
  fontFamily: fontFamily(theme),
  fontWeight: 400,

  inherit: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
  },

  d1: {
    ...d1(theme),
    fontWeight: fontWeight(theme, 'semibold'),
  },

  d2: {
    ...d2(theme),
    fontWeight: fontWeight(theme, 'semibold'),
  },

  h1: {
    ...h1(theme),
    fontWeight: fontWeight(theme, 'semibold'),
  },

  h2: {
    ...h2(theme),
    fontWeight: fontWeight(theme, 'semibold'),
  },

  h3: {
    ...h3(theme),
    fontWeight: fontWeight(theme, 'semibold'),
  },

  h4: {
    ...h4(theme),
    fontWeight: fontWeight(theme, 'bold'),
  },

  body1: body1(theme),
  body2: body2(theme),

  pxToRem: theme.typography.pxToRem,
})
