// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-js": () => import("./../../../src/pages/404/404.js" /* webpackChunkName: "component---src-pages-404-404-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brand-guide-brand-guidelines-js": () => import("./../../../src/pages/brand-guide/BrandGuidelines.js" /* webpackChunkName: "component---src-pages-brand-guide-brand-guidelines-js" */),
  "component---src-pages-brand-guide-index-js": () => import("./../../../src/pages/brand-guide/index.js" /* webpackChunkName: "component---src-pages-brand-guide-index-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/contact/Contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-how-coursera-works-how-coursera-works-js": () => import("./../../../src/pages/how-coursera-works/HowCourseraWorks.js" /* webpackChunkName: "component---src-pages-how-coursera-works-how-coursera-works-js" */),
  "component---src-pages-how-coursera-works-index-js": () => import("./../../../src/pages/how-coursera-works/index.js" /* webpackChunkName: "component---src-pages-how-coursera-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sms-terms-and-conditions-index-js": () => import("./../../../src/pages/sms-terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-sms-terms-and-conditions-index-js" */),
  "component---src-pages-sms-terms-and-conditions-sms-terms-and-conditions-js": () => import("./../../../src/pages/sms-terms-and-conditions/SMSTermsAndConditions.js" /* webpackChunkName: "component---src-pages-sms-terms-and-conditions-sms-terms-and-conditions-js" */),
  "component---src-pages-whatsapp-terms-and-conditions-index-js": () => import("./../../../src/pages/whatsapp-terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-whatsapp-terms-and-conditions-index-js" */),
  "component---src-pages-whatsapp-terms-and-conditions-whats-app-terms-and-conditions-js": () => import("./../../../src/pages/whatsapp-terms-and-conditions/WhatsAppTermsAndConditions.js" /* webpackChunkName: "component---src-pages-whatsapp-terms-and-conditions-whats-app-terms-and-conditions-js" */)
}

