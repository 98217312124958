import { createMuiTheme } from '@material-ui/core/styles'
import breakpoints from './breakpoints'
import typography from './typography'
import palette from './palette'

const themeName = 'CDS'

const CDSTheme = createMuiTheme({
  themeName,
  breakpoints,
  palette,
  spacing: 8,
})

CDSTheme.typography = typography(CDSTheme)

export default CDSTheme
